<template>
  <v-row justify="center" align="center" class="full-height">
    <v-col cols="12" class="text-center">
      <h1>Site under construction</h1>
    </v-col>
  </v-row>
</template>

<style>
.full-height {
  height: 90vh;
}
</style>
<!--template>
  <v-row>
    <v-carousel hide-delimiter-background show-arrows="hover" cycle="true">
      <v-carousel-item :src="require('@/assets/PIM_13.png')" cover>
      </v-carousel-item>

      <v-carousel-item
        :src="require('@/assets/PIM_11.png')"
        cover
      ></v-carousel-item>
      <v-carousel-item
        :src="require('@/assets/PIM_12.png')"
        cover
      ></v-carousel-item>
    </v-carousel>
  </v-row>
  <v-container class="responsiveFont responsiveWidth">
    <v-row
      ><v-col cols="12"
        ><h2 class="textTitle">
          INDUSTRIAL PARK (PIM) - MOSNITA NOUA – TIMIS county
          <h6 class="textSubtitle">
            integrated industrial park for the development of applications,
            products and services through advanced technologies
          </h6>
        </h2>
      </v-col></v-row
    >
    <v-divider length="90%"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-img :src="require('@/assets/PIM_4.png')" contain class="textBox" />
        <v-divider length="90%"></v-divider>

        <p class="textBox">
          The industrial park MOSNITA NOUA is located near the municipality of
          Timisoara in the commune of Mosnita Noua, on a privately-owned land of
          about 100 ha. The strategic positioning on the western side of
          Romania, close to the borders with Serbia and Hungary, allows quick
          access to the European road E70 and the A1 highway that connects
          Vienna, Budapest, Belgrade with Bucharest and the port of Constanta.
          The Industrial Park is private property and represents a precisely
          delimited area, where economic activities, research, industrial
          production, and services will be carried out with the aim of
          developing and valorizing the natural and human resources of the area
          in general, and especially the workforce made up of future graduates
          from the university education in Timisoara. At the same time, the
          connection with Belgrade via the new Timisoara-Pancevo expressway, the
          access to the waterway on the Danube represents another package of
          facilities for transporting goods from the future PIMOTIM technology
          park.
        </p>

        <p class="textBox">
          The MOSNITA NOUA INDUSTRIAL PARK aims to promote, through future
          traditional but also technological investors, activities such as the
          processing of very high-volume information, such as BIG DATA or
          processing industries in the field of energy-saving technologies, in
          the field of IT & C and advanced technologies such as robotics,
          artificial intelligence, machine learning, digitization, cyber
          security, green mobility, smart city, aquaponics, vertical gardens,
          intelligent agriculture, utility drones, etc.
        </p>

        <p class="textBox">
          The park will have three distinct areas: the current industrial one, a
          technological area created in a concentric building dedicated to
          advanced technology companies, and a third energy self-sustainability
          area embodied in a photovoltaic park.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      screenSize: 0,
    };
  },
  methods: {
    getAspectRatio() {
      this.screenSize = window.screen.width / window.screen.height;
    },
  },
};
</script>

<style scoped>
.textComponent {
  margin: 20px 20px;
}
.textBox {
  text-align: justify;
  margin: 25px 25px;
}
.textTitle {
  text-align: center;
  margin: 25px 25px;
}
.textSubtitle {
  color: #999;
}
.v-divider {
  margin: 0px 5%;
}
.moreButton {
  position: absolute;
  margin-left: 10%;
  margin-top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.aspect-ratio {
  position: relative;
}

.aspect-ratio > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.textList {
  text-align: justify;
  margin: 40px;
}
/* Default font size for all screens */
.responsiveFont {
  font-size: 15px;
}

/* Change font size for screens smaller than 768px (tablets) */
@media (max-width: 767px) {
  .responsiveFont {
    font-size: 13px;
  }
}

/* Change font size for screens smaller than 576px (smartphones) */
@media (max-width: 575px) {
  .responsiveFont {
    font-size: 11px;
  }
}
/* For desktop screens */
@media only screen and (min-width: 1200px) {
  .responsiveWidth {
    max-width: 80%;
  }
}

/* For tablet screens */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .responsiveWidth {
    max-width: 90%;
  }
}

/* For smartphone screens */
@media only screen and (max-width: 767px) {
  .responsiveWidth {
    max-width: 100%;
  }
}
.noBulletPoints {
  list-style-type: none;
  font-style: italic;
}
.centerList {
  display: flex;
}
</style-->
