<template>
  <v-app>
    <v-app-bar app color="blue" dark height="30">
      <p class="constructionText">
        Site in constructie. Imaginile sunt cu titlu informativ si nu trebuie
        considerate ca atare.
      </p>
    </v-app-bar>
    <v-app-bar>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="toggleDrawer"
      ></v-app-bar-nav-icon>
      <div>
        <a
          :href="`tel:${phoneNumber}`"
          style="text-decoration: none; color: inherit"
        >
          <v-btn rounded class="circle-btn"><v-icon>mdi-phone</v-icon></v-btn>
        </a>
      </div>
      <div class="center">
        <NavigationOverlays />
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="left" temporary width="400">
      <NavigationDrawer />
    </v-navigation-drawer>

    <v-main>
      <div ref="fixedComponent" class="fixed-component">
        <SocialButtons />
      </div>
      <router-view />
    </v-main>

    <v-spacer></v-spacer>

    <FooterBar />
  </v-app>
</template>

<script>
import { ref } from "vue";
import SocialButtons from "./components/SocialButtons.vue";
import FooterBar from "./components/FooterBar.vue";
import NavigationOverlays from "./components/NavigationOverlays.vue";
import NavigationDrawer from "./components/NavigationDrawer.vue";

export default {
  data() {
    return {
      theme: ref("light"),
      drawer: false,
      group: null,
      phoneNumber: "0787812657",
    };
  },
  components: {
    SocialButtons,
    FooterBar,
    NavigationOverlays,
    NavigationDrawer,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    handleScroll() {
      const fixedComponent = this.$refs.fixedComponent;
      const threshold = 200;

      if (window.scrollY > threshold) {
        fixedComponent.classList.add("pinned");
      } else {
        fixedComponent.classList.remove("pinned");
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.fixed-component {
  display: none;
}
.circle-btn {
  height: 56px; /* Adjust height and width to create a perfect circle */
  width: 56px;
  min-width: 56px;
  border-radius: 50%; /* Ensures the button is a circle */
}
.pinned {
  display: block;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  transition: all 2.3s ease-in-out;
}
.center {
  margin: auto;
  width: 70%;
}
.constructionText {
  margin: auto;
}
</style>
