<template>
  <v-row justify="center" align="center" class="full-height">
    <v-col cols="12" class="text-center">
      <h1>Pagina in constructie</h1>
    </v-col>
  </v-row>
</template>

<style>
.full-height {
  height: 90vh;
}
</style>
