import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/Home/HomeView.vue";
//import HomeRomanianView from "../views/Home/HomeRomanianView.vue";
import HomeParcelsView from "../views/Home/HomeParcelsView.vue";
import HomeLocationView from "../views/Home/HomeLocationView.vue";
import HomeObjectivesView from "../views/Home/HomeObjectivesView.vue";
import HomeFacilitiesView from "../views/Home/HomeFacilitiesView.vue";

import NzebView from "../views/Technologies/NzebView.vue";
import PassiveHouseView from "../views/Technologies/PassiveHouseView.vue";
import PhotovoltaicView from "../views/Technologies/PhotovoltaicView.vue";

import AlbinaView from "../views/DevelopmentLocations/AlbinaView.vue";
import AristocratView from "../views/DevelopmentLocations/AristocratView.vue";
import MosnitaView from "../views/DevelopmentLocations/MosnitaView.vue";
import UrseniView from "../views/DevelopmentLocations/UrseniView.vue";

import ContactView from "../views/Contact/ContactView.vue";
import PlusView from "../views/Contact/PlusView.vue";
import ProgramView from "../views/Contact/ProgramView.vue";

const routes = [
  {
    path: "/",
    name: "homePage",
    component: HomeView,
  },
  {
    path: "/facilities",
    name: "homeFacilities",
    component: HomeFacilitiesView,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  /*{
    path: "/English",
    name: "homeRomaninanPage",
    component: HomeRomanianView,
  },*/
  {
    path: "/parcels",
    name: "parcelsPage",
    component: HomeParcelsView,
  },
  {
    path: "/location",
    name: "locationPage",
    component: HomeLocationView,
  },
  {
    path: "/objectives",
    name: "objectivesPage",
    component: HomeObjectivesView,
  },

  {
    path: "/nzeb",
    name: "nzebPage",
    component: NzebView,
  },
  {
    path: "/passive-house",
    name: "passiveHousePage",
    component: PassiveHouseView,
  },
  {
    path: "/photovoltaic",
    name: "photovoltaicPage",
    component: PhotovoltaicView,
  },

  {
    path: "/albina",
    name: "albinaPage",
    component: AlbinaView,
  },
  {
    path: "/aristocrat",
    name: "aristocratPage",
    component: AristocratView,
  },
  {
    path: "/mosnita",
    name: "mosnitaPage",
    component: MosnitaView,
  },
  {
    path: "/urseni",
    name: "urseniPage",
    component: UrseniView,
  },

  /*{
    path: "/location",
    name: "locationPage",
    component: LocationView,
  },*/
  {
    path: "/plus",
    name: "plusPage",
    component: PlusView,
  },
  {
    path: "/program",
    name: "programPage",
    component: ProgramView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
