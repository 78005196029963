<template>
  <v-row justify="center" align="center" class="full-height">
    <v-col cols="12" class="text-center">
      <h1>Site under construction</h1>
    </v-col>
  </v-row>
</template>

<style>
.full-height {
  height: 90vh;
}
</style>
<!--template>
  <v-row>
    <v-carousel hide-delimiter-background show-arrows="hover" cycle="true">
      <v-carousel-item :src="require('@/assets/PIM_13.png')" cover>
      </v-carousel-item>

      <v-carousel-item
        :src="require('@/assets/PIM_11.png')"
        cover
      ></v-carousel-item>
      <v-carousel-item
        :src="require('@/assets/PIM_12.png')"
        cover
      ></v-carousel-item>
    </v-carousel>
  </v-row>
  <v-container class="responsiveFont responsiveWidth">
    <v-row
      ><v-col cols="12"
        ><h2 class="textTitle">
          INDUSTRIAL PARK (PIM) - MOSNITA NOUA – TIMIS County
          <h6 class="textSubtitle">
            integrated industrial park for the development of applications,
            products and services through advanced technologies
          </h6>
        </h2>
      </v-col></v-row
    >
    <v-divider length="90%"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-img :src="require('@/assets/PIM_3.png')" contain class="textBox" />
        <v-divider length="90%"></v-divider>

        <p class="textBox">
          The concept of the park is an integrated technological industrial
          park. This principle means a solution to many problems that the
          industrial parks in Romania have faced in the last decade, and for
          which the GEVAGRO management has designed real and pragmatic solutions
          as follows:
        </p>

        <ul class="textList">
          <li>
            For the highly qualified workforce, the MOSNITA INDUSTRIAL PARK will
            develop the dual education center for the students of the Timisoara
            university center but also for the young people who will populate
            the Mosnita Noua metropolitan area in full real estate growth, the
            population of the commune growing rapidly from year to year!
          </li>
          <li>
            Facilities inside the park to solve some employee problems
            (kindergarten, afterschool, banking and shopping services,
            relaxation or fitness areas during work breaks, medical and dental
            services).
          </li>
          <li>
            The selection of investors promoting the jobs of the future, which
            will ensure in the next three decades a global connection that will
            favor economic exchanges and integration beyond the borders of the
            states. This, at the same time, determines changes in the strategy
            of large companies, so that the MOSNITA INDUSTRIAL PARK will not
            only be a supplier of talents but also a market for the development
            of professional skills adapted to the new times.
          </li>
          <li>
            The new media ecology through which technologies (video, digital
            animations, games, etc.) have created a new language of
            communication and a separate universe will constitute the center of
            the coworking area, ultra-modern spaces for IT&C activities through
            which reputation and identity are built, bringing transparency to
            work and life.
          </li>
        </ul>

        <p class="textBox">
          Thus, through the large-scale technological investors that we target
          for PIM, the achievement of the 10 skills that will be sought in the
          future on the labor market will be ensured:
        </p>

        <ul class="textList">
          <li>
            Sense-making - the ability to understand the meaning of the
            discussed topic and other complex aspects that cannot be "coded" and
            processed by a computer.
          </li>
          <li>
            Social intelligence - the ability to connect with others in a direct
            and deep way, to feel and interact.
          </li>
          <li>
            Novel & adaptive thinking - the ability to adapt to different tasks
            and to find innovative solutions.
          </li>
          <li>
            Cross-cultural competency - the ability to work in diverse cultural
            environments.
          </li>
          <li>
            Computational thinking – The ability to translate vast amounts of
            data (big data management) into abstract concepts and to understand
            the connections.
          </li>
          <li>
            New media literacy – The ability to develop content based on new
            media forms and to use it in persuasive communication.
          </li>
          <li>
            Transdisciplinarity – The ability to understand and use concepts
            from different subjects.
          </li>

          <li>
            Design mindset – The ability to understand the tasks and to adapt
            the work processes to achieve the desired objectives.
          </li>
          <li>
            Cognitive load management – The ability to filter information
            according to its importance and to use cognitive resources to the
            maximum.
          </li>
          <li>
            Virtual collaboration – The ability to engage and work productively
            even as a member of a virtual team.
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      screenSize: 0,
    };
  },
  methods: {
    getAspectRatio() {
      this.screenSize = window.screen.width / window.screen.height;
    },
  },
};
</script>

<style scoped>
.textComponent {
  margin: 20px 20px;
}
.textBox {
  text-align: justify;
  margin: 25px 25px;
}
.textTitle {
  text-align: center;
  margin: 25px 25px;
}
.textSubtitle {
  color: #999;
}
.v-divider {
  margin: 0px 5%;
}
.moreButton {
  position: absolute;
  margin-left: 10%;
  margin-top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.aspect-ratio {
  position: relative;
}

.aspect-ratio > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.textList {
  text-align: justify;
  margin: 40px;
}
/* Default font size for all screens */
.responsiveFont {
  font-size: 15px;
}

/* Change font size for screens smaller than 768px (tablets) */
@media (max-width: 767px) {
  .responsiveFont {
    font-size: 13px;
  }
}

/* Change font size for screens smaller than 576px (smartphones) */
@media (max-width: 575px) {
  .responsiveFont {
    font-size: 11px;
  }
}
/* For desktop screens */
@media only screen and (min-width: 1200px) {
  .responsiveWidth {
    max-width: 80%;
  }
}

/* For tablet screens */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .responsiveWidth {
    max-width: 90%;
  }
}

/* For smartphone screens */
@media only screen and (max-width: 767px) {
  .responsiveWidth {
    max-width: 100%;
  }
}
.noBulletPoints {
  list-style-type: none;
  font-style: italic;
}
.centerList {
  display: flex;
}
</style-->
