<template>
  <v-row>
    <v-carousel hide-delimiter-background show-arrows="hover" cycle="true">
      <v-carousel-item :src="require('@/assets/PIM_13.png')" cover>
      </v-carousel-item>
      <v-carousel-item
        :src="require('@/assets/PIM_11.png')"
        cover
      ></v-carousel-item>
      <v-carousel-item
        :src="require('@/assets/PIM_12.png')"
        cover
      ></v-carousel-item>
    </v-carousel>
  </v-row>
  <!-- v-row justify="center">
    <v-col cols="2" md="2">
      <HomeCard />
    </v-col>
    <v-col cols="2" md="2">
      <HomeCard />
    </v-col>
    <v-col cols="2" md="2">
      <HomeCard />
    </v-col>
  </v-row-->

  <v-container class="responsiveFont responsiveWidth">
    <v-row>
      <v-col cols="12"
        ><h2 class="textTitle">
          INDUSTRIAL PARK (PIM) - MOSNITA NOUA – TIMIS County
          <h6 class="textSubtitle">
            integrated industrial park for the development of applications,
            products and services through advanced technologies
          </h6>
        </h2>
      </v-col></v-row
    >
    <v-divider length="90%"></v-divider>
    <p class="textBox">
      In corelatie cu tendinta industriala europeana de dezvoltare a companiilor
      de tehnologie avansata, SC GEVAGRO SRL din Mosnita Noua, judetul Timis
      si-a propus crearea unei zone de dezvoltare industriala si tehnologica
      prin doua parcuri specializate. Cele doua parcuri au fost aprobate prin
      ordinul nr 1156/08.04.2024 al ministrului dezvoltării, lucrărilor publice
      și administrației publicat in MONITORUL OFICIAL AL ROMANIEI
      nr344/12.08.2024, în conformitate cu legea parcurilor industriale nr.
      186l/2013 în scopul atragerii investitorilor privați, asa cum este
      prezentat in facsimilul de mai jos. Terenurile pentru care s-au acordat
      titlurile de parc industrial sunt amplasate pe teritoriul administrativ al
      comunei Moșnița Nouă, județul Timiș și au următoarele caracteristici:
    </p>

    <ul class="textList">
      <li>
        a) Prima locație are o suprafață de 7,31 ha și este identificată în baza
        extraselor de carte funciară pentru informare nr. 400711 și 405007,
        eliberate de Oficiul de Cadastru și Publicitate Imobiliară Timiș, Biroul
        de Cadastru și Publicitate Imobiliară Timișoara, la data de 13.11.2023;
      </li>
      <li>
        b) A doua locație are o suprafață de 9,91 ha și este identificată în
        baza extraselor de carte funciară pentru informare nr. 401063, 401064,
        404999, 405003, 405006 și 411680, eliberate de Oficiul de Cadastru și
        Publicitate Imobiliară Timiș, Biroul de Cadastru și Publicitate
        Imobiliară Timișoara, la data de 13.11.2023.
      </li>
    </ul>

    <v-row no-gutters>
      <v-col cols="6"
        ><v-img
          :src="require('@/assets/Document3.png')"
          aspect-ratio="4 / 3"
          class="textBox"
          cover /></v-col
      ><v-col cols="6"
        ><v-img
          :src="require('@/assets/Document.png')"
          aspect-ratio="4 / 3"
          class="textBox"
          cover
      /></v-col>
    </v-row>
    <v-img :src="require('@/assets/Document2.png')" contain class="textBox" />
    <v-divider length="90%"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-divider length="90%"></v-divider>

        <p class="textBox">
          Pentru popularea acestor doua parcuri, vizam branduri globale in
          domeniul de viitor al tehnologiilor profunde pentru parcul tehnologic,
          unde se va dezvolta un BIG DATA CENTER dedicat generatiei de ingineri,
          programatori, proiectanti, arhitecti, etc proveniti din mediul
          universitar cu prioritate timisorean. PIM-PARCUL INDUSTRIAL TEHNOLOGIC
          MOSNITA NOUA va functiona pe actuala platforma (fosta ferma de vite)
          pe 17,2 ha in Mosnita Noua, Calea Medves 100, unde isi desfasoara
          activitatea deja 27 de firme cu peste 200 de angajati.
        </p>

        <v-img :src="require('@/assets/PIM_1.png')" contain class="textBox" />
        <p class="textImage">
          DISCLAIMER: Imaginile sunt cu titlu informativ si nu trebuie
          considerate ca atare.
        </p>
        <v-divider length="90%"></v-divider>
        <p class="textBox">
          Parcul tehnologic (zona de 7,31 ha) este conceput ca un parc de
          dezvoltare-cercetare a tehnologiilor avansate nepoluante si
          autosustenabile in cadrul unui BIG DATA CENTER ce va atrage câteva
          sute de angajati cu pregatire superioara. Cladirea de la suprafata se
          doreste a avea o arhitectura similara proiectului celebrei arhitecte
          ZAHA HADID sugerata ca titlu informal prin imaginea de mai jos (fara
          sa fie luata in considerare), proiectarea fiind in etapa de finalizare
          conceptuala. La subsolul cladirii pe mai multe nivele se va dezvolta
          un centru de date de circa 1 exaoctet care va necesita un consum de
          100 MW pentru care chiar linga acest parc a inceput constructia unei
          centrale fotovoltaice de 50 MW si o alta de 120 MW in Bazos de catre
          un investitor strategic european.
        </p>
        <v-img :src="require('@/assets/PIM_2.png')" contain class="textBox" />
        <p class="textImage">
          DISCLAIMER: Imaginile sunt cu titlu informativ si nu trebuie
          considerate ca atare.
        </p>
        <v-divider length="90%"></v-divider>
        <p class="textBox">
          Parcul industrial (zona de 9,91 ha) va cuprinde hale industriale
          personalizate pe profilul investitorilor in domenii cum sunt
          automotive. robotica, realitate augmentata, inteligenta artificiala,
          etc. Poziționarea strategică a celor doua parcuri pe partea de vest a
          României, aproape de granițele cu Serbia și Ungaria, permite accesul
          rapid la drumul european E70 și autostrada A1 care leagă Viena,
          Budapesta, Belgradul de București și portul Constanța. Parcul
          Industrial este proprietate privată și reprezintă o zonă precis
          delimitată, în care se vor desfășura activități economice, cercetare,
          producție industrială și servicii în scopul dezvoltării și
          valorificării resurselor naturale și umane ale zonei în general și în
          special a forței de muncă. formată din viitori absolvenţi ai
          învăţământului universitar din Timişoara. De asemenea legătura cu
          Belgrad prin noul drum expres Timișoara-Pancevo si viitoarea
          autostrada, accesul la calea navigabilă pe Dunăre reprezintă un alt
          pachet de facilități de transport asociate celor doua parcuri
          industrial tehnologice.
        </p>
        <v-row no-gutters>
          <v-col cols="6"
            ><v-img
              :src="require('@/assets/PIM_3.png')"
              aspect-ratio="4 / 3"
              class="textBox"
              cover /></v-col
          ><v-col cols="6"
            ><v-img
              :src="require('@/assets/PIM_4.png')"
              aspect-ratio="4 / 3"
              class="textBox"
              cover
          /></v-col>
        </v-row>
        <p class="textImage">
          DISCLAIMER: Imaginile sunt cu titlu informativ si nu trebuie
          considerate ca atare.
        </p>
        <v-divider length="90%"></v-divider>
        <p class="textBox">
          PIM PARCUL INDUSTRIAL-TEHNOLOGIC MOSNITA NOUA isi propune sa
          promoveze, prin viitori investitori traditionali, dar si tehnologici,
          activitati precum prelucrarea de informatii de volum foarte mare,
          industriile de prelucrare in domeniul tehnologiilor de economisire a
          energiei, in domeniul IT. & C și tehnologii avansate precum robotica,
          inteligența artificială, învățarea automată, digitizarea, securitatea
          cibernetică, mobilitatea verde, orașul inteligent, acvaponia,
          grădinile verticale, agricultura inteligentă, dronele utilitare etc.
        </p>
        <v-img :src="require('@/assets/PIM_5.png')" class="textBox" contain />
        <p class="textImage">
          DISCLAIMER: Imaginile sunt cu titlu informativ si nu trebuie
          considerate ca atare.
        </p>
        <v-divider length="90%"></v-divider>
        <p class="textBox">
          Parcul va avea doua zone distincte: o zonă tehnologică creată într-o
          clădire cu o arhitectura futurista, dedicată companiilor de tehnologie
          avansată și o a doua zonă industriala si de autosustenabilitate
          energetică. Conceptul de parc industrial-tehnologic integrat, înseamnă
          o soluție la multe probleme cu care parcurile industriale din România
          s-au confruntat în ultimul deceniu și pentru care conducerea GEVAGRO a
          conceput soluții reale și pragmatice astfel:
        </p>

        <ul class="textList">
          <li>
            - Pentru forta de munca cu inalta calificare, PARCUL INDUSTRIAL
            MOSNITA va dezvolta centrul de invatamant dual pentru studentii
            centrului universitar Timisoara dar si pentru tinerii care vor
            popula zona metropolitana Mosnita Noua in plina crestere imobiliara,
            populatia comunei. creste rapid de la an la an!
          </li>
          <li>
            - Facilități în incinta parcului pentru rezolvarea unor probleme ale
            angajaților (grădiniță, afterschool, servicii bancare și de
            cumpărături, zone de relaxare sau fitness în pauzele de lucru,
            servicii medicale și stomatologice).
          </li>
          <li>
            - Selecția investitorilor care promovează locurile de muncă ale
            viitorului, care să asigure în următoarele trei decenii o legătură
            globală care să favorizeze schimburile economice și integrarea
            dincolo de granițele statelor. Aceasta, in acelasi timp, va
            determina schimbari in strategia marilor companii, astfel incat
            PARCUL INDUSTRIAL MOSNITA sa fie nu doar un furnizor de talente ci
            si o piata de dezvoltare a competentelor profesionale adaptate
            noilor vremuri.
          </li>
          <li>
            - Noua ecologie media prin care tehnologiile (video, animații
            digitale, jocuri etc.) au creat un nou limbaj de comunicare și un
            univers separat va constitui centrul zonei de coworking, spații
            ultramoderne pentru activități IT&C prin care reputația și
            identitatea sunt construite, aducând transparență muncii și vieții.
          </li>
        </ul>

        <v-img :src="require('@/assets/PIM_10.png')" contain class="textBox" />
        <p class="textImage">
          DISCLAIMER: Imaginile sunt cu titlu informativ si nu trebuie
          considerate ca atare.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import HomeCard from "@/components/HomeCard.vue";

export default {
  name: "HomeView",
  data() {
    return {
      screenSize: 0,
    };
  },
  components: {
    // HomeCard,
  },
  methods: {
    getAspectRatio() {
      this.screenSize = window.screen.width / window.screen.height;
    },
  },
};
</script>

<style scoped>
.textComponent {
  margin: 20px 20px;
}
.textBox {
  text-align: justify;
  margin: 25px 25px;
}
.textImage {
  text-align: right;
  margin: 25px 25px;
  opacity: 50%;
}
.textTitle {
  text-align: center;
  margin: 25px 25px;
}
.textSubtitle {
  color: #999;
}
.v-divider {
  margin: 0px 5%;
}
.moreButton {
  position: absolute;
  margin-left: 10%;
  margin-top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.aspect-ratio {
  position: relative;
}

.aspect-ratio > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.textList {
  text-align: justify;
  margin: 40px;
}
/* Default font size for all screens */
.responsiveFont {
  font-size: 15px;
}

/* Change font size for screens smaller than 768px (tablets) */
@media (max-width: 767px) {
  .responsiveFont {
    font-size: 13px;
  }
}

/* Change font size for screens smaller than 576px (smartphones) */
@media (max-width: 575px) {
  .responsiveFont {
    font-size: 11px;
  }
}
/* For desktop screens */
@media only screen and (min-width: 1200px) {
  .responsiveWidth {
    max-width: 80%;
  }
}

/* For tablet screens */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .responsiveWidth {
    max-width: 90%;
  }
}

/* For smartphone screens */
@media only screen and (max-width: 767px) {
  .responsiveWidth {
    max-width: 100%;
  }
}
.noBulletPoints {
  list-style-type: none;
  font-style: italic;
}
.centerList {
  display: flex;
}
</style>
