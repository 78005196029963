<template>
  <v-row justify="center" align="center" class="full-height">
    <v-col cols="12" class="text-center">
      <h1>Site under construction</h1>
    </v-col>
  </v-row>
</template>

<style>
.full-height {
  height: 90vh;
}
</style>
<!--template>
  <v-row>
    <v-carousel hide-delimiter-background show-arrows="hover" cycle="true">
      <v-carousel-item :src="require('@/assets/PIM_13.png')" cover>
      </v-carousel-item>

      <v-carousel-item
        :src="require('@/assets/PIM_11.png')"
        cover
      ></v-carousel-item>
      <v-carousel-item
        :src="require('@/assets/PIM_12.png')"
        cover
      ></v-carousel-item>
    </v-carousel>
  </v-row>
  <v-container class="responsiveFont responsiveWidth">
    <v-row
      ><v-col cols="12"
        ><h2 class="textTitle">
          INDUSTRIAL PARK (PIM) - MOSNITA NOUA – TIMIS County
          <h6 class="textSubtitle">
            integrated industrial park for the development of applications,
            products and services through advanced technologies
          </h6>
        </h2>
      </v-col></v-row
    >
    <v-divider length="90%"></v-divider>
    <v-img :src="require('@/assets/PIM_6.png')" contain class="textBox" />
    <v-divider length="90%"></v-divider>
    <v-img :src="require('@/assets/PIM_9.png')" contain class="textBox" />
    <v-divider length="90%"></v-divider>
    <v-row>
      <v-col cols="12">
        <p class="textBox">
          In essence, through the conceptual project, the park will have
          ultra-modern halls and a "heart" that will ensure the pulse of the
          operation of all the industries that will be developed on the 100
          hectares.
        </p>

        <p class="textBox">
          "The heart", the central building, will concentrate beside the
          headquarters of the park administrator, insurance elements for
          supporting with advanced technologies the production locations and
          services from the MOSNITA NOUA integrated technological industrial
          park, but also with personalized services such as research, marketing,
          advanced design and of human resources through the development of
          modern forms of dual education.
        </p>

        <p class="textBox">
          The central area will have specific collateral services for staff with
          families who will come to the area every day. We are referring here to
          the kindergarten for the employees' children, commercial spaces,
          banking services, but also an impressive smart fitness center
          correlated with natural nutrition services for maintaining the
          superior quality of the labor force of those who work in the park.
          This aspect will solve one of the current problems of the industrial
          parks, the absence of the original elements of attractiveness of the
          labor force that brings added value with an above-average training.
          The activity for the future customers of the industrial park will take
          place as a form of access through renting or buying, but also through
          the coworking system through which any small company or independent
          programmers can rent a place where they can benefit from the services
          of the technological industrial park and at the same time work in the
          creative field of computerized design, programming, graphics, visual
          effects, industrial design, online content, etc.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      screenSize: 0,
    };
  },
  methods: {
    getAspectRatio() {
      this.screenSize = window.screen.width / window.screen.height;
    },
  },
};
</script>

<style scoped>
.textComponent {
  margin: 20px 20px;
}
.textBox {
  text-align: justify;
  margin: 25px 25px;
}
.textTitle {
  text-align: center;
  margin: 25px 25px;
}
.textSubtitle {
  color: #999;
}
.v-divider {
  margin: 0px 5%;
}
.moreButton {
  position: absolute;
  margin-left: 10%;
  margin-top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.aspect-ratio {
  position: relative;
}

.aspect-ratio > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.textList {
  text-align: justify;
  margin: 40px;
}
/* Default font size for all screens */
.responsiveFont {
  font-size: 15px;
}

/* Change font size for screens smaller than 768px (tablets) */
@media (max-width: 767px) {
  .responsiveFont {
    font-size: 13px;
  }
}

/* Change font size for screens smaller than 576px (smartphones) */
@media (max-width: 575px) {
  .responsiveFont {
    font-size: 11px;
  }
}
/* For desktop screens */
@media only screen and (min-width: 1200px) {
  .responsiveWidth {
    max-width: 80%;
  }
}

/* For tablet screens */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .responsiveWidth {
    max-width: 90%;
  }
}

/* For smartphone screens */
@media only screen and (max-width: 767px) {
  .responsiveWidth {
    max-width: 100%;
  }
}
.noBulletPoints {
  list-style-type: none;
  font-style: italic;
}
.centerList {
  display: flex;
}
</style-->
