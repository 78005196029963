<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row><v-btn icon="mdi-facebook" color="blue"></v-btn></v-row>
        <v-row><v-btn icon="mdi-instagram" color="blue"></v-btn></v-row>
        <v-row
          ><v-btn icon="mdi-facebook-messenger" color="blue"></v-btn
        ></v-row>
        <v-row><v-btn icon="mdi-twitter" color="blue"></v-btn></v-row>
        <v-row><v-btn icon="mdi-plus" color="blue"></v-btn></v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SocialButtons",
};
</script>

<style scoped>
.v-btn {
  margin: 10px;
}
.v-container {
  max-width: 80px;
}
</style>
