<template>
  <v-img :src="require('@/assets/PIM_Contact.png')" cover max-height="1200px">
    <v-container class="customContainer">
      <v-row>
        <v-col
          cols="4"
          @click="
            showModal = true;
            selectedImg = 0;
          "
        >
          <v-img
            :src="require('@/assets/PIM_Plan_1.png')"
            contain
            max-height="900px"
          ></v-img>
        </v-col>
        <v-col
          cols="4"
          @click="
            showModal = true;
            selectedImg = 1;
          "
        >
          <v-img
            :src="require('@/assets/PIM_Plan_2.png')"
            contain
            max-height="900px"
          ></v-img>
        </v-col>
        <v-col
          cols="4"
          @click="
            showModal = true;
            selectedImg = 2;
          "
        >
          <v-img
            :src="require('@/assets/PIM_Plan_3.png')"
            contain
            max-height="900px"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          @click="
            showModal = true;
            selectedImg = 3;
          "
        >
          <v-img
            :src="require('@/assets/PIM_Plan_4.png')"
            contain
            max-height="800px"
          ></v-img>
        </v-col>
      </v-row>

      <v-dialog v-model="showModal" fullscreen hide-overlay>
        <v-img :src="images[selectedImg]" @click="showModal = false"></v-img>
      </v-dialog>
    </v-container>
  </v-img>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require("@/assets/PIM_Plan_1.png"),
        require("@/assets/PIM_Plan_2.png"),
        require("@/assets/PIM_Plan_3.png"),
        require("@/assets/PIM_Plan_4.png"),
      ],
      showModal: false,
      selectedImg: null,
    };
  },
};
</script>

<style scoped>
.customContainer {
  max-width: 1000px;
  max-height: fit-content;
  margin: 0 auto;
}
.v-container:first-child {
  background-color: #fff;
}
</style>
